<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";
import type { TypeToolCompanyComparison } from "~/types/contentful";
import type {
  TypePpiCompany,
  TypePpiMethodology,
  TypePpiBreakdown,
} from "~/types/csv/TypePpi";
import { pushDataLayerEvent } from "~/lib/client-data-layer";

const props = defineProps<{
  fields: TypeToolCompanyComparison<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();

const { data: dataSource } = await useLazyFetch<
  TypeDataSourceParsed<TypePpiCompany, TypePpiMethodology, TypePpiBreakdown>
>("/api/data-source", {
  query: { id: props.fields.dataSource?.sys.id },
  server: false,
});

const selectedCompanies = ref<
  {
    name: string;
    nameShort: string;
    ticker: string;
    scoring: NonNullable<TypeDataSourceParsed["scoring"]>;
    breakdown: TypePpiBreakdown;
  }[]
>([]);

const selectedTickers = computed(() =>
  selectedCompanies.value.map((c) => c.ticker),
);

const availableCompanies = computed(() =>
  Object.fromEntries(
    new Map(
      dataSource.value?.data
        ?.filter((c) => !selectedTickers.value.includes(c.Ticker))
        .map((company) => [company.Ticker, company["Name Short"]]),
    ),
  ),
);

const selectCompany = async (ticker: string) => {
  const { data: dataSource } = await useFetch<
    TypeDataSourceParsed<TypePpiCompany, TypePpiMethodology, TypePpiBreakdown>
  >("/api/data-source", {
    query: { id: props.fields.dataSource?.sys.id, company: ticker },
  });

  if (
    dataSource.value?.company?.["Name Short"]
    && dataSource.value.scoring
    && dataSource.value.breakdown
  ) {
    const breakdown = dataSource.value.breakdown;
    breakdown.forEach((kpi) => {
      kpi.subGroups.forEach(
        (subKpi) =>
          (subKpi.score
            = dataSource.value?.company?.[subKpi.code as keyof TypePpiCompany]
            !== ""
              ? Number(
                dataSource.value?.company?.[
                  subKpi.code as keyof TypePpiCompany
                ],
              )
              : undefined),
      );
    });
    selectedCompanies.value.push({
      name: dataSource.value.company.Name,
      nameShort: dataSource.value.company["Name Short"],
      ticker: dataSource.value.company.Ticker,
      scoring: dataSource.value.scoring,
      breakdown: dataSource.value.breakdown,
    });

    pushDataLayerEvent("evCompanyComparisonSelect", {
      selectedCompany: dataSource.value.company.Name,
      selectedCompanies: selectedCompanies.value.map((company) => company.name),
    });
  }
};

const removeCompany = (index: number) => {
  selectedCompanies.value.splice(index, 1);
};

const openKpis = ref<string[]>([]);
const isOpen = (code: string) => openKpis.value.includes(code);
const toggleOpen = (code: string) => {
  if (isOpen(code)) {
    openKpis.value = openKpis.value.filter((i) => i !== code);
  } else {
    openKpis.value.push(code);

    pushDataLayerEvent("evCompanyComparisonAccordionOpen", {
      title: dataSource.value?.breakdown?.reduce(
        (acc, kpi) => (!acc && kpi.code === code ? kpi.title : acc),
        "",
      ),
    });
  }
};
</script>

<template>
  <Container class="tool--company-comparison py-4">
    <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
    <RichTextRenderer :document="fields.text" />
    <div
      class="compare grid gap-x-0"
      :class="
        'grid-cols-'
          + (selectedCompanies.length > 1
            ? selectedCompanies.length === 5
              ? 6
              : selectedCompanies.length + 2
            : 3)
      "
    >
      <div class="flex flex-col">
        <section class="compare__header" />
        <section>
          <h4 class="compare__panel mb-0 ps-[32px]">
            Risk Score
          </h4>
        </section>
        <section
          v-for="kpi in dataSource?.breakdown"
          :key="kpi.code"
          class="accordion mb-3 grid gap-3 rounded-l bg-level-standard ps-[32px]"
          :class="{ open: isOpen(kpi.code) }"
        >
          <h4
            class="compare__panel m-0 flex items-center"
            @click="toggleOpen(kpi.code)"
          >
            <NuxtIcon
              name="fairr:arrow"
              size="20"
              class="arrow ml-[-22px] mr-[6px]"
            />
            {{ kpi.title }}
          </h4>
          <div class="accordion__content">
            <div
              v-for="(subKpi, s) in kpi.subGroups"
              :key="s"
              class="compare__panel border-ui-grey2"
              :class="{
                'border-b': s < kpi.subGroups.length - 1,
                'border-t': s === 0,
              }"
            >
              {{ subKpi.title }}
            </div>
          </div>
        </section>
      </div>
      <div
        v-for="(company, index) in selectedCompanies"
        :key="company.nameShort"
        class="flex flex-col border-l border-ui-grey2"
        :class="{ 'border-r': index === selectedCompanies.length - 1 }"
      >
        <section class="compare__header px-3">
          <div class="w-full flex-wrap bg-level-standard p-3">
            <div class="flex w-full items-center">
              <div class="grow">
                {{ company.ticker }}
              </div>
              <div
                class="pointer w-[24px] rounded border border-ui-grey2 text-center"
                @click="removeCompany(index)"
              >
                <NuxtIcon
                  name="fairr:remove"
                  size="20"
                  class="rotate-180"
                />
              </div>
            </div>

            <div class="w-full">
              <h3>{{ company.nameShort }}</h3>
            </div>
          </div>
        </section>
        <section>
          <div class="compare__panel p-3">
            <UiProgressBar
              class="grow"
              :coverage="company.scoring.result / 100"
              :colour="company.scoring.resultColour"
            />
            <div class="compare__score">
              <div
                class="ms-2 flex h-[28px] items-center justify-center rounded text-white"
                :style="{
                  backgroundColor: company.scoring.resultColour,
                }"
              >
                <span class="mr-1 font-semibold">{{
                  company.scoring.result
                }}</span>
                <span class="text-xs tracking-[1.5px]">/{{ company.scoring.maxResult }}</span>
              </div>
            </div>
          </div>
        </section>
        <section
          v-for="kpi in company.breakdown"
          :key="kpi.code"
          class="accordion mb-3 grid gap-3 bg-level-standard"
          :class="{ open: isOpen(kpi.code) }"
        >
          <div
            class="compare__panel p-3"
            @click="toggleOpen(kpi.code)"
          >
            <UiProgressBar
              class="grow"
              :coverage="Number(kpi.score) / 100"
              :colour="kpi.scoreColour"
            />
            <div class="compare__score">
              <div
                class="ms-2 flex h-[28px] items-center justify-center rounded text-white"
                :style="{
                  backgroundColor:
                    kpi.code.includes('ALP') && kpi.score === 0
                      ? '#bababa'
                      : kpi.scoreColour,
                }"
              >
                <span class="mr-1 font-semibold">
                  {{ kpi.score }}
                </span>
                <span
                  class="text-xs tracking-[1.5px]"
                >
                  /{{ kpi.maxScore }}
                </span>
              </div>
            </div>
          </div>
          <div class="accordion__content">
            <div
              v-for="(subKpi, s) in kpi.subGroups"
              :key="s"
              class="compare__panel border-ui-grey2 p-3"
              :class="{
                'border-b': s < kpi.subGroups.length - 1,
                'border-t': s === 0,
              }"
            >
              <UiProgressBar
                class="h-[8px] grow"
                :coverage="(subKpi.score || 0) / 100"
                :colour="String(subKpi.scoreColour)"
              />
              <div class="compare__score ps-2">
                <div
                  class="m-auto flex h-[22px] max-w-[60px] items-center justify-center rounded text-xs tracking-[1.5px] text-white"
                  :style="{
                    backgroundColor:
                      subKpi.code.includes('ALP') && subKpi.score === 0
                        ? '#bababa'
                        : String(subKpi.scoreColour),
                  }"
                >
                  <span v-if="subKpi.score === undefined">N/A</span>
                  <span v-else>{{ Math.round(Number(subKpi.score)) }}/{{ 100 }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="px-3">
        <select
          v-if="selectedCompanies.length < 5"
          class="compare__header"
          @input="
            if (($event.target as HTMLSelectElement | null)?.value) {
              selectCompany(($event.target as HTMLSelectElement).value);
              ($event.target as HTMLSelectElement).value = '';
            }
          "
        >
          <option
            value=""
            selected
            disabled
          >
            Select company
          </option>
          <option
            v-for="(name, ticker) in availableCompanies"
            :key="ticker"
            :value="ticker"
          >
            {{ name }}
          </option>
        </select>
      </div>
    </div>
    <!-- needed for loading Tailwind classes for dynamic comparison columns -->
    <div class="grid-cols-5" />
    <div class="grid-cols-6" />
  </Container>
</template>

<style lang="scss" scoped>
.compare {
  width: calc(100% + 32px);
  margin-left: -32px;

  &__header {
    height: 96px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__panel {
    height: 60px;
    display: flex;
    align-items: center;

    &:deep(.rounded-sm.bg-level-standard) {
      background-color: white;
    }
  }

  &__score {
    max-width: 130px;
    width: 40%;
    text-align: center;
  }
}

.accordion {
  gap: 0;
  user-select: none;

  &__content {
    transition: max-height 0.4s ease;
    max-height: 600px;
    overflow: hidden;
  }

  &:deep(.arrow) {
    transform: rotate(180deg);
    margin-top: 4px;
  }

  &:not(.open) {
    .accordion__content {
      max-height: 0;
    }

    &:deep(.arrow) {
      transform: rotate(0deg);
      margin-top: -2px;
    }
  }
}
</style>
